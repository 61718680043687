export const enum Regulators {
  VFSC = 'vfsc',
  VFSC2 = 'vfsc2',
  CIMA = 'cima',
  FCA = 'fca',
  ASIC = 'asic',
}

export const enum TASK_TYPE {
  CUSTOM_TASK = 1,
  ASSIGNED_TASK = 2,
}

export const LEADS_FILTER = {
  TODAY: 'Today',
  MONTH: 'Month',
  FIRST_TRADE_ACCOUNT: 'First TradeIn Demo Account',
  INACTIVE: 'Inactive Demo Account'
}

export const RING_CENTRAL_CALLBACK_SATE = {
  HOME: 'home',
  TASK: 'task',
  DETAIL_CLIENT: 'clientdetail',
  DETAIL_LEAD: 'clientlead',
  LIST_CLIENT: 'clientlist',
  LIST_LEAD: 'leadlist',
  TEST: 'testcall'
}

export const RING_CENTRAL_CALLBACK_ROUTE = {
  [RING_CENTRAL_CALLBACK_SATE.HOME]: '/salework-station/dashboard',
  [RING_CENTRAL_CALLBACK_SATE.TASK]: '/tasks',
  [RING_CENTRAL_CALLBACK_SATE.DETAIL_CLIENT]: '/clients/information/',
  [RING_CENTRAL_CALLBACK_SATE.DETAIL_LEAD]: '/clients/information/',
  [RING_CENTRAL_CALLBACK_SATE.LIST_CLIENT]: '/clients',
  [RING_CENTRAL_CALLBACK_SATE.LIST_LEAD]: '/leads',
  [RING_CENTRAL_CALLBACK_SATE.TEST]: '/test/call'
}

export const COOKIE_TOKEN_NAME = 'swsToken'
export const COOKIE_REFRESH_TOKEN_NAME = 'swsRefreshToken'
export const NO_REQUEST_AUTH_TOKEN_LIST = [
  '/api/v1/auth/session/login',
  '/api/v1/auth/session/logout'
]